<template>
  <div class="div-wrapper_">
    <section class="vh-100" style="background-color: #f7f7f8; width: 100%">
      <div class="container py-5">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-md-12 col-xl-12">
            <div
              class="card_"
              style="border-radius: 15px; background: #f7f7f8 !important"
            >
              <div class="card-body text-center">
                <div class="mt-3 mb-4">
                  <img
                    src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2-bg.webp"
                    class="rounded-circle img-fluid profileImage"
                    style="width: 100px"
                  />
                </div>
                <h4 class="mb-1 user-name expert-blurred-name2"></h4>

                <p class="text-muted mb-2 experience"></p>

                <p class="text-muted mb-2">
                  <img src="/img/Map-location.svg" />
                  <span class="mx-1 location" >London, UK (UTC +1)</span>

                  <img src="/img/Bag.svg" class="ml-4" />
                  <span class="mx-1 organization_">Coinbase</span>
                </p>

                 <center class="compensation_hr"><hr style="border: 0.7px solid #ccc;width:50%" class="my-4 mt-4 " />

                <div  class="row investor_list compensation_"></div>

                </center>

                 

                <hr style="border: 0.7px solid #bebbbb; " class="my-4" />

                <div
                  class="alert alert-success mx-2"
                  role="alert"
                  style="text-align: left; color:#1FA564;"
                >
                  <p class="text-muted mb-2">

                     <div class="my-2 h5 matcher" style="font-weight: 600; ">96% Match</div>
                     <div class="my-3 ticker">Eze ticks the following boxes for you;</div>

                     <div class="my-3">
                       <ul>
                        <li class="roleMatch">Product Designer</li>
                        <li class="skillMatch">Proficient in Figma</li>
                        <li class="platformMatch">Has experience in Finance, Travel & Web 3.0</li>
                        <li class="industryMatchMain"><span class="industryMatch"></span></li>
                        </ul>


                       
                     </div>

                  </p>



                  
                </div>


                <hr style="border: 1px solid #bebbbb" class="my-5 mt-3" />

                <div class="my-2 h5 titleHeader">About</div>

                <div class="my-2">
                  <p  style="text-align: left; color: #6F7680;" class="aboutUser">
                    I was born in Boston, New England, and owe my first instructions in literature to the free grammar-schools established there. 
                    I therefore give one hundred pounds sterling to my executors.<br/>
                    
I was born in Boston, New England, and owe my first instructions in literature to the free grammar-schools established there. I therefore give one hundred pounds sterling to my executors
                       </p>
                </div>


                 <hr style="border: 1px solid #bebbbb" class="my-5" />

                 <div class="my-2 h5 titleHeader" style="">Skills</div>

                <div class="my-3 userSkill_" ref="userSkill"  style="text-align: left;">

                    

                </div>

                <ul class="flex-container wrap"></ul>


<br>
                <hr style="border: 1px solid #bebbbb;margin-top:20px" class="" /><br>

                 <div class="my-2 h5 titleHeader" style="">Experience</div>
                 

                 <div class="work_experienceData"></div>
                 <div class="work_viewMore">View more...</div>

                 <div class="work_viewLess" style="margin-top: -10px;">View less...</div>



                <hr style="border: 1px solid #bebbbb;margin-top:60px" class="" /><br>

                 <div class="my-2 h5 titleHeader" style="">Education</div>
                 
                  <div class="educationDataMain"></div>

                  
                 





                


              
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
//import $ from "jquery";

export default {
  name: "ProfileData", // this is what the Warning is talking about.
  props: [],
   data: () => ({
   
      
    
   
  }),
  components: {},
  methods: {

   

    
  },
  watch: {

 
 
    
  

  },
  
  
  mounted() {
    this.$nextTick(async () => {

   


 })

    //
   

    
   
  },
  
};
</script>

<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}

.expert-blurred-name {
   
}



</style>
