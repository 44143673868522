var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "div-wrapper_" }, [
    _c(
      "section",
      {
        staticClass: "vh-100",
        staticStyle: { "background-color": "#f7f7f8", width: "100%" },
      },
      [
        _c("div", { staticClass: "container py-5" }, [
          _c(
            "div",
            {
              staticClass:
                "row d-flex justify-content-center align-items-center h-100",
            },
            [
              _c("div", { staticClass: "col-md-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card_",
                    staticStyle: {
                      "border-radius": "15px",
                      background: "#f7f7f8 !important",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "card-body text-center" },
                      [
                        _vm._m(0),
                        _c("h4", {
                          staticClass: "mb-1 user-name expert-blurred-name2",
                        }),
                        _c("p", { staticClass: "text-muted mb-2 experience" }),
                        _vm._m(1),
                        _c("center", { staticClass: "compensation_hr" }, [
                          _c("hr", {
                            staticClass: "my-4 mt-4 ",
                            staticStyle: {
                              border: "0.7px solid #ccc",
                              width: "50%",
                            },
                          }),
                          _c("div", {
                            staticClass: "row investor_list compensation_",
                          }),
                        ]),
                        _c("hr", {
                          staticClass: "my-4",
                          staticStyle: { border: "0.7px solid #bebbbb" },
                        }),
                        _vm._m(2),
                        _c("hr", {
                          staticClass: "my-5 mt-3",
                          staticStyle: { border: "1px solid #bebbbb" },
                        }),
                        _c("div", { staticClass: "my-2 h5 titleHeader" }, [
                          _vm._v("About"),
                        ]),
                        _vm._m(3),
                        _c("hr", {
                          staticClass: "my-5",
                          staticStyle: { border: "1px solid #bebbbb" },
                        }),
                        _c("div", { staticClass: "my-2 h5 titleHeader" }, [
                          _vm._v("Skills"),
                        ]),
                        _c("div", {
                          ref: "userSkill",
                          staticClass: "my-3 userSkill_",
                          staticStyle: { "text-align": "left" },
                        }),
                        _c("ul", { staticClass: "flex-container wrap" }),
                        _c("br"),
                        _c("hr", {
                          staticStyle: {
                            border: "1px solid #bebbbb",
                            "margin-top": "20px",
                          },
                        }),
                        _c("br"),
                        _c("div", { staticClass: "my-2 h5 titleHeader" }, [
                          _vm._v("Experience"),
                        ]),
                        _c("div", { staticClass: "work_experienceData" }),
                        _c("div", { staticClass: "work_viewMore" }, [
                          _vm._v("View more..."),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "work_viewLess",
                            staticStyle: { "margin-top": "-10px" },
                          },
                          [_vm._v("View less...")]
                        ),
                        _c("hr", {
                          staticStyle: {
                            border: "1px solid #bebbbb",
                            "margin-top": "60px",
                          },
                        }),
                        _c("br"),
                        _c("div", { staticClass: "my-2 h5 titleHeader" }, [
                          _vm._v("Education"),
                        ]),
                        _c("div", { staticClass: "educationDataMain" }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-3 mb-4" }, [
      _c("img", {
        staticClass: "rounded-circle img-fluid profileImage",
        staticStyle: { width: "100px" },
        attrs: {
          src: "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2-bg.webp",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-muted mb-2" }, [
      _c("img", { attrs: { src: "/img/Map-location.svg" } }),
      _c("span", { staticClass: "mx-1 location" }, [
        _vm._v("London, UK (UTC +1)"),
      ]),
      _c("img", { staticClass: "ml-4", attrs: { src: "/img/Bag.svg" } }),
      _c("span", { staticClass: "mx-1 organization_" }, [_vm._v("Coinbase")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "alert alert-success mx-2",
        staticStyle: { "text-align": "left", color: "#1FA564" },
        attrs: { role: "alert" },
      },
      [
        _c("p", { staticClass: "text-muted mb-2" }),
        _c(
          "div",
          {
            staticClass: "my-2 h5 matcher",
            staticStyle: { "font-weight": "600" },
          },
          [_vm._v("96% Match")]
        ),
        _c("div", { staticClass: "my-3 ticker" }, [
          _vm._v("Eze ticks the following boxes for you;"),
        ]),
        _c("div", { staticClass: "my-3" }, [
          _c("ul", [
            _c("li", { staticClass: "roleMatch" }, [
              _vm._v("Product Designer"),
            ]),
            _c("li", { staticClass: "skillMatch" }, [
              _vm._v("Proficient in Figma"),
            ]),
            _c("li", { staticClass: "platformMatch" }, [
              _vm._v("Has experience in Finance, Travel & Web 3.0"),
            ]),
            _c("li", { staticClass: "industryMatchMain" }, [
              _c("span", { staticClass: "industryMatch" }),
            ]),
          ]),
        ]),
        _c("p"),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "my-2" }, [
      _c(
        "p",
        {
          staticClass: "aboutUser",
          staticStyle: { "text-align": "left", color: "#6F7680" },
        },
        [
          _vm._v(
            "\n                    I was born in Boston, New England, and owe my first instructions in literature to the free grammar-schools established there. \n                    I therefore give one hundred pounds sterling to my executors."
          ),
          _c("br"),
          _vm._v(
            "\n                    \nI was born in Boston, New England, and owe my first instructions in literature to the free grammar-schools established there. I therefore give one hundred pounds sterling to my executors\n                       "
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }